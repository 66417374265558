import { initializeApp } from "firebase/app";
import { 
  getAuth,
  GoogleAuthProvider,
  signInWithPopup, 
  onAuthStateChanged, 
  signOut, 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  createUserWithEmailAndPassword,
  signInAnonymously
 } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAsERay89j6_u9lZxc8dbG8zSqXaJFRWJk",
  authDomain: "devsume-beta.firebaseapp.com",
  projectId: "devsume-beta",
  storageBucket: "devsume-beta.appspot.com",
  messagingSenderId: "159353281979",
  appId: "1:159353281979:web:2bf56dc1bb2f8ca685789e",
  measurementId: "G-NRSWPTE8BN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, 
  provider, 
  analytics, 
  signInWithPopup, 
  onAuthStateChanged, 
  signOut, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signInAnonymously, 
  sendPasswordResetEmail, 
  db 
};
